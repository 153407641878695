@import "olympus-frontend/src/@core/scss/base/bootstrap-extended/variables";
@import "olympus-frontend/src/@core/scss/base/components/variables-dark";

.btn {
  font-weight: 400 !important;
}

.btn-outline-primary:hover:not(.disabled):not(:disabled) {
  background-color: #ff6800;
  color: #ffffff;
}

.btn-outline-danger:hover:not(.disabled):not(:disabled) {
  background-color: #ea5455;
  color: #ffffff;
}

.select__multi-value {
  background-color: $primary !important;
}

// KendoGrid
.activity_field_must_edit, .plant_field_must_edit {
  background-color: #ffe4e4 !important;
}

.form-control[readonly] {
    opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
    background-color: #ffffff;
    opacity: 1;
}


// in attendance/configuration/index.js
// For handling cell focus on a kendogrid
.k-widget.k-grid{
  max-height: 1000px;
}
.k-widget.k-grid.kendo_rows_not_selectable table tbody > tr:hover {
  background-color: initial;

  > td:not(:first-child):hover {
    background-color: $body-bg;
  }
}

// When loading...
.k-widget.k-grid.kendo_loading table tbody {
  opacity: 0.5 !important;
}

.k-widget.k-grid.kendo_rows_not_selectable table tbody > tr > td.disable-td,
.k-widget.k-grid.kendo_rows_not_selectable table tbody > tr > td.disable-td:hover {
  background-color: #eaeaea !important;
  cursor: default !important;
}

body.dark-layout {

  .animation-background {
    background:  #3b4253;
  }

  #reportrange {
    border-color: #3b4253;
  }

  //** HTML Editor scss
  .html_editor-wrapper {
    border-color: #3b4253
  }

  .html_editor-toolbar {
    border-color: #3b4253;
    background: #283046;
  }


  //** End of

  .k-timepicker {
    border-color: #404656;
    background: none !important;

    .k-picker-wrap {
      .k-select {
        background: #404656;
      }
    }
  }

  .activity_field_must_edit, .plant_field_must_edit {
    background-color: #ffe4e4 !important;
  }

  .k-widget.k-grid.kendo_rows_not_selectable table tbody > tr > td:not(:first-child):hover {
    background-color: $theme-dark-table-hover-bg;
  }

  .k-widget.k-grid.kendo_rows_not_selectable table tbody > tr > td.disable-td,
  .k-widget.k-grid.kendo_rows_not_selectable table tbody > tr > td.disable-td:hover {
    background-color: $theme-dark-input-disabled-bg !important;
    cursor: default !important;
  }

  .k-scheduler-head > .k-scheduler-group:first-child > .k-scheduler-row:first-child {
    .k-scheduler-cell.k-heading-cell {
      background: $theme-dark-card-bg !important;
    }
  }

  .k-scheduler-cell.k-slot-cell {
    background: $theme-dark-card-bg;
  }
}

// Kendo scheduler for spanning width of a cell!
.k-scheduler-layout {
  table-layout: fixed !important;
}

.k-scheduler-layout > tbody > tr > td:first-child {
  width: 289px !important;
}

.k-scheduler-content .k-scheduler-table,
.k-scheduler-head .k-scheduler-table {
  width: 2000px !important;
}

.k-scheduler-head > .k-scheduler-group:first-child > .k-scheduler-row:first-child {
  .k-scheduler-cell.k-side-cell {
    min-width: 80px !important;
  }

  .k-scheduler-cell.k-heading-cell {
    min-width: 840px !important;
    background: white !important;
  }
}

.k-scheduler-head > .k-scheduler-group:nth-child(2) > .k-scheduler-row > .k-scheduler-cell.k-group-cell {
  min-width: 862px !important;
}

.k-scheduler-body > .k-scheduler-group > .k-scheduler-row > .k-scheduler-cell.k-group-cell {
  min-width: 80px !important;
}

.k-scheduler-head > .k-scheduler-group:first-child > .k-scheduler-row:nth-child(2) > .k-scheduler-cell.k-group-cell,
.k-scheduler-body > .k-scheduler-group > .k-scheduler-row > .k-scheduler-cell.k-group-cell {
  min-width: 862px !important;
}


.k-timepicker {
  .k-picker-wrap {
    .k-select {
      border-radius: 3px;
    }
  }

  .k-dateinput-wrap {
    input {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.45;
      color: #828d99;
      font-family: 'IBM Plex Sans', serif;
    }
  }

}

// Timepicker exception (used into EditAgenda.js ../pages/agenda/components/EditAgenda)
.timepicker-need-padding {
  padding: 2px;
}

.k-animation-container {
  z-index: 10003 !important; // set the desired value based on the application requirements
}

//** HTML Editor scss
.html_editor-wrapper {
  border: 1px solid #d8d6de;
  border-radius: 4px;
}

.html_editor-editor {
  padding: 0px 10px;
}

//** END HTMLEditor


// ** APEXCHARTS
.apexcharts-legend {
  overflow-x: auto !important;
  white-space: nowrap;
}

.chart-da-pianificare {
  .apexcharts-xaxis, .apexcharts-yaxis {
    display: none;
  }
}

#reportrange {
  cursor: pointer;
  width: 100%;
  border-color: #cccccc;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 7px;
}

.me-05{
  margin-right: 0.50rem !important;
}

.animation-background{
  background: #f3f4f4
}

.fast-option {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.fast-option-selected.fast-option {
  background-color: #ff6800;
  color: #fff;
}

.fast-option-focused.fast-option {
  background-color: rgba(255, 104, 0, 0.12);
  color: #ff6800;
}

.input_no_style{
  border: none;
  background: none;
  outline: none;
  box-shadow: none !important;

    &:focus, &:active, &:hover, &:valid {
      outline: none;
      box-shadow: none;
    }

}
.input_no_style_title {
  border: none;
  background: none !important;
  outline: none;
  box-shadow: none !important;
  font-size: 16px;
  font-weight: 400;
  font-style: italic;
  width: 400px;
  padding: 0px;

  &:focus, &:active, &:hover, &:valid {
    outline: none !important;
    box-shadow: none !important;
  }
}

//** Kendo ListBoxes
.k-list-scroller.k-selectable {
  padding: 20px 10px;
  border-radius: 10px;
}
.k-listbox-toolbar {
  display: flex;
  align-items: center;
}
.k-listbox .k-list-scroller .k-reset.k-list {
  height: auto;
  max-height: 100%;
}